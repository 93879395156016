body {
  background-color: #000;
  color: #fff;
  font-family: "Finger Paint", cursive;
}

.App {
  padding-top: 30px;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.App img {
  border-radius: 20px;
}

.text {
  position: absolute;
  z-index: 9999;
  left: 20px;
  bottom: 20px;
}

.banner {
  z-index: 999999;
  position: relative;
}

.btn-primary {
  background-color: #000;
  border: 1px solid #fff;
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  margin-top: 20px;
  z-index: 999999;
  position: relative;
  transition: all 0.3s linear;
  filter: blur(0);
  font-family: "Erica One", cursive;
}

.banner {
  padding: 0 20px;
  max-width: 620px;
  margin: 0 auto;
}

.banner img {
  width: 100%;
}

.btn-primary.blur {
  filter: blur(2px);
}

canvas {
  background: #000;
}

.socials {
  position: absolute;
  z-index: 99999;
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* top: 100px; */
  bottom: 100px;
}

.socials img {
  width: 50px;
}

#tsparticles {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
